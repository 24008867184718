import { IssueTrackerSettings as IssueSettings } from '@nimbly-technologies/nimbly-common';
import { IssueSettingsRequest } from 'core/domain/entities/setting/issueSettingEntity';
import { issueSettingsRepository } from 'core/infrastructure/repositories/setting/issueSettingRepository';
import debounce from 'lodash/debounce';
import {
	CreateAutoDueDatePayload,
	CreateAutoDueDateResponse,
	GetAutoDueDatesResponse,
	GetDefaultAutoDueDateResponse,
	Site,
	UpdateAutoDueDatePayload,
	UpdateAutoDueDateResponse,
} from './issueTrackerSettingsUtils.types';

export const getIssueTrackerSetting = async (organizationId: string): Promise<IssueSettings> => {
	return issueSettingsRepository.getIssueSettings(organizationId);
};

export const createDefaultIssueTrackerSetting = async (organizationId: string): Promise<IssueSettings> => {
	return issueSettingsRepository.createDefaultIssueSettings(organizationId);
};

export const updateIssueTrackerSetting = async (
	organizationId: string,
	issueSettingsId: string,
	payload: IssueSettingsRequest,
): Promise<IssueSettings> => {
	return issueSettingsRepository.updateIssueSettings(organizationId, issueSettingsId, payload);
};

export const getAutoDueDates = async (searchQuery = '', page = 1, limit = 15): Promise<GetAutoDueDatesResponse> => {
	return issueSettingsRepository.getAutoDueDates(searchQuery, page, limit);
};

export const debounceGetAutoDueDates = debounce(getAutoDueDates, 200);

export const getSites = async (): Promise<Site[]> => {
	return issueSettingsRepository.getSites();
};

export const createAutoDueDate = async (payload: CreateAutoDueDatePayload): Promise<CreateAutoDueDateResponse> => {
	return issueSettingsRepository.createAutoDueDate(payload);
};

export const updateAutoDueDate = async (payload: UpdateAutoDueDatePayload): Promise<UpdateAutoDueDateResponse> => {
	return issueSettingsRepository.updateAutoDueDate(payload);
};

export const deleteAutoDueDate = async (autoDueDateId: string): Promise<boolean> => {
	return issueSettingsRepository.deleteAutoDueDate(autoDueDateId);
};

export const getDefaultAutoDueDate = async (): Promise<GetDefaultAutoDueDateResponse | undefined> => {
	return issueSettingsRepository.getDefaultAutoDueDate();
};

export type DueDateSettingType = 'manual' | 'automatic';
