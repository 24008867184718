import { ReactNode } from 'react';
import { OptionTypeBase } from 'core/presentation/ui/Select/ReactSelectExtended';

export interface BaseSelectOptionType<T = string> extends OptionTypeBase {
	label: string;
	value: T;
	icon?: ReactNode;
	isDisabled?: boolean;
}

export interface BaseOptionObject<T = string> {
	[key: string]: BaseSelectOptionType<T>;
}

interface mapOptionsDataToArrayProps {
	data: Array<{ [key: string]: any }>;
	key?: string;
	value: string;
	label: string;
}

export const mapOptionsDataToArray = ({ data, value, label }: mapOptionsDataToArrayProps): BaseSelectOptionType[] =>
	(data || []).map((d) => ({
		value: d[value],
		label: d[label],
	}));

export interface MapOptionsDataProps extends mapOptionsDataToArrayProps {
	key: string;
}

export const mapOptionsDataToObject = ({ data, key, value, label }: MapOptionsDataProps): BaseOptionObject =>
	Object.values(data || []).reduce(
		(previousMap, d) => ({
			...previousMap,
			[d[key]]: {
				value: d[value],
				label: d[label],
			},
		}),
		{},
	);

export const mapOptionsToValues = (options: BaseSelectOptionType[]): string[] => {
	if (!options) return [];

	return options.map(({ value }) => value);
};

export const mapValuesToOptions = (values: string[], hashMap: BaseOptionObject): BaseSelectOptionType[] => {
	if (!hashMap || Object.keys(hashMap).length <= 0 || typeof values?.map !== 'function') return [];

	return values.map((value) => hashMap[value]);
};
