import { IssueSettings, IssueSettingsRequest } from 'core/domain/entities/setting/issueSettingEntity';
import {
	CreateAutoDueDatePayload,
	CreateAutoDueDateResponse,
	GetAutoDueDatesResponse,
	GetDefaultAutoDueDateResponse,
	IssueSettingSite as Site,
	UpdateAutoDueDatePayload,
	UpdateAutoDueDateResponse,
} from 'core/domain/models/setting/issueSettingModel';
import { IIssueSettingsRepository } from 'core/domain/iRepositories/setting/iIssueSettingRepository';

import { apiURL } from 'config/baseURL';
import API from 'helpers/api';

class IssueSettingsRepository implements IIssueSettingsRepository {
	getIssueSettings = async (organizationId: string): Promise<IssueSettings> => {
		const url = `${apiURL}/organizations/${organizationId}/issue-tracker-settings`;
		const token = await API.getFirebaseToken();
		const result = await API.getRetry(url, token);

		const response = await result.json();
		if (result.status === 200) {
			return response.data as IssueSettings;
		} else if (result.status === 404) {
			return this.createDefaultIssueSettings(organizationId);
		} else {
			throw new Error();
		}
	};

	createDefaultIssueSettings = async (organizationId: string): Promise<IssueSettings> => {
		const url = `${apiURL}/organizations/${organizationId}/issue-tracker-settings`;
		const token = await API.getFirebaseToken();
		const result = await API.post(url, token, {
			useAutoDueDate: false,
		});

		const response = await result.json();
		if (result.status === 200) {
			return response.data as IssueSettings;
		} else {
			throw new Error();
		}
	};

	updateIssueSettings = async (
		organizationId: string,
		issueSettingsId: string,
		payload: IssueSettingsRequest,
	): Promise<IssueSettings> => {
		const url = `${apiURL}/organizations/${organizationId}/issue-tracker-settings/${issueSettingsId}`;
		const token = await API.getFirebaseToken();
		const result = await API.put(url, token, payload);

		const response = await result.json();
		if (result.status === 200) {
			return response.data as IssueSettings;
		} else {
			throw new Error();
		}
	};

	getAutoDueDates = async (searchQuery = '', page = 1, limit = 15): Promise<GetAutoDueDatesResponse> => {
		const params = new URLSearchParams({ search: searchQuery, page: `${page}`, limit: `${limit}` });
		const url = `${apiURL}/issues/auto-due-dates?${params}`;

		const token = await API.getFirebaseToken();
		const result = await API.get(url, token);

		const response = await result.json();
		if (result.status === 200) {
			return response.data as GetAutoDueDatesResponse;
		} else {
			throw new Error();
		}
	};

	getSites = async (): Promise<Site[]> => {
		const url = `${apiURL}/sites/sites-for-autoduedate`;

		const token = await API.getFirebaseToken();
		const result = await API.get(url, token);

		const response = await result.json();
		if (result.status === 200) {
			return response.data as Site[];
		} else {
			throw new Error();
		}
	};

	createAutoDueDate = async (payload: CreateAutoDueDatePayload): Promise<CreateAutoDueDateResponse> => {
		const url = `${apiURL}/issues/auto-due-dates`;
		const token = await API.getFirebaseToken();
		const result = await API.post(url, token, payload);

		const response = await result.json();
		if (result.status === 200) {
			return response.data as CreateAutoDueDateResponse;
		} else {
			throw new Error();
		}
	};

	updateAutoDueDate = async (payload: UpdateAutoDueDatePayload): Promise<UpdateAutoDueDateResponse> => {
		const url = `${apiURL}/issues/auto-due-dates/${payload.autoDueDateID}`;
		const token = await API.getFirebaseToken();
		const result = await API.put(url, token, payload);

		const response = await result.json();
		if (result.status === 200) {
			return response.data as UpdateAutoDueDateResponse;
		} else {
			throw new Error();
		}
	};

	deleteAutoDueDate = async (autoDueDateId: string): Promise<boolean> => {
		const url = `${apiURL}/issues/auto-due-dates/${autoDueDateId}`;
		const token = await API.getFirebaseToken();
		const result = await API.delete(url, token);
		if (result.status === 200) {
			return true;
		} else {
			throw new Error();
		}
	};

	getDefaultAutoDueDate = async (): Promise<GetDefaultAutoDueDateResponse | undefined> => {
		const url = `${apiURL}/issues/auto-due-dates/default`;

		const token = await API.getFirebaseToken();
		const result = await API.get(url, token);

		const response = await result.json();
		if (result.status === 200) {
			return response.data as GetDefaultAutoDueDateResponse;
		} else if (result.status === 404) {
			return undefined;
		} else {
			throw new Error();
		}
	};
}

const issueSettingsRepository = new IssueSettingsRepository();

export { issueSettingsRepository, IssueSettingsRepository };
