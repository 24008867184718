import { action } from 'typesafe-actions';
import * as types from './questionnaireDetail.actionTypes';
import {
	CategoryPool,
	CategoryIdPool,
	EditingQuestionPool,
	InventoryQuestionPool,
	QuestionRangeInverted,
	QuestionPool,
	AllowRecommendationPool,
	AllowTagPool,
	AllowReferencePool,
	AutoAssignmentType,
	EditingConditionalQuestionPool,
	AllowConditionalTagPool,
	AllowConditionalReferencePool,
	AllowConditionalRecommendationPool,
	QuestionRangeFlagValue,
	QuestionRangeFlagType,
	QuestionConditionalRangeFlagType,
	QuestionConditionalRangeInverted,
	AllowConditionalAttachmentPool,
	AllowAttachmentPool,
	AutoAssignmentTypeV2,
} from './types';
import { QuestionFlagLabel, QuestionCategoryFlagLabel } from '../../types/common';
import { Question } from 'nimbly-common';
import { CategoryAttributes } from '@nimbly-technologies/nimbly-common';
/**
 *
 */
export const resetState = () => action(types.RESET_STATE, {});

export const resetStateCustomerFeedback = () => action(types.RESET_STATE_CUSTOMER_FEEDBACK, {});

/**
 *
 */
export const setCategories = (categories: CategoryPool) => action(types.SET_CATEGORIES, { categories });

/**
 *
 */
export const setCategoryIds = (categoryIds: CategoryIdPool) => action(types.SET_CATEGORY_IDS, { categoryIds });

/**
 *
 */
export const setVideoSet = (total: number) => action(types.SET_VIDEO_SET, { total });

/**
 *
 */
export const setQuestionWithVideos = (total: number) => action(types.SET_QUESTION_WITH_VIDEO, { total });

/**
 *
 */
export const setQuestionWithScore = (total: number) => action(types.SET_QUESTION_WITH_SCORE, { total });

/**
 *
 */
export const setTotalScoreWeight = (total: number) => action(types.SET_TOTAL_SCORE_WEIGHT, { total });

/**
 *
 */
export const setInventoryQuestions = (inventoryQuestions: InventoryQuestionPool) =>
	action(types.SET_INVENTORY_QUESTIONS, { inventoryQuestions });

/**
 *
 */
export const setInventoryIndex = (inventoryIndex: number) => action(types.SET_INVENTORY_INDEX, { inventoryIndex });

/**
 *
 */
export const setSelectedRangeFlagType = (questionId: string, selectedRangeFlagType: QuestionRangeFlagValue) =>
	action(types.SET_SELECTED_RANGE_FLAG_TYPE, { questionId, selectedRangeFlagType });

/**
 *
 */
export const setSelectedConditionalRangeFlagType = (
	parentId: string,
	condition: string,
	childId: number,
	selectedRangeFlagType: QuestionRangeFlagValue,
) => action(types.SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE, { parentId, condition, childId, selectedRangeFlagType });

export const setSelectedRangeFlagTypeBatch = (selectedRangeFlagType: QuestionRangeFlagType) =>
	action(types.SET_SELECTED_RANGE_FLAG_TYPE_BATCH, { selectedRangeFlagType });

export const setSelectedConditionalRangeFlagTypeBatch = (selectedRangeFlagType: QuestionConditionalRangeFlagType) =>
	action(types.SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE_BATCH, { selectedRangeFlagType });

/**
 *
 */
export const setIsRangeFlagInverted = (questionId: string, inversed: boolean) =>
	action(types.SET_IS_RANGE_FLAG_INVERTED, { questionId, inversed });

export const setIsEditable = (isEditable: boolean) => action(types.SET_IS_EDITABLE, { isEditable });

/**
 *
 */
export const setIsConditionalRangeFlagInverted = (
	parentId: string,
	condition: string,
	childId: number,
	inversed: boolean,
) => action(types.SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED, { parentId, condition, childId, inversed });

/**
 *
 */
export const setIsRangeFlagInvertedBatch = (rangeFlagInverted: QuestionRangeInverted) =>
	action(types.SET_IS_RANGE_FLAG_INVERTED_BATCH, { rangeFlagInverted });

/**
 *
 */
export const setIsConditionalRangeFlagInvertedBatch = (rangeFlagInverted: QuestionConditionalRangeInverted) =>
	action(types.SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED_BATCH, { rangeFlagInverted });

/**
 *
 */
export const setCheckField = (checking: boolean) => action(types.SET_CHECK_FIELD, { checking });

/**
 *
 */
export const setTitle = (title: string) => action(types.SET_TITLE, { title });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for root question.
 * When enabled, additional form for selected question will be shown.
 */
export const setEditingQuestions = (editingQuestions: EditingQuestionPool) =>
	action(types.SET_EDITING_QUESTIONS, { editingQuestions });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for conditional question.
 * When enabled, additional form for selected question will be shown.
 */
export const setEditingConditionalQuestions = (editingQuestions: EditingConditionalQuestionPool) =>
	action(types.SET_EDITING_CONDITIONAL_QUESTIONS, { editingQuestions });

/**
 *
 */
export const setQuestions = (questions: QuestionPool) => action(types.SET_QUESTIONS, { questions });
export const setConditionalQuestions = (parentId: string, condition: string, questions: Question[]) =>
	action(types.SET_CONDITIONAL_QUESTIONS, { parentId, condition, questions });

/**
 *
 */
export const setDateCreated = (date: string) => action(types.SET_DATE_CREATED, { date });
export const setDateUpdated = (date: string) => action(types.SET_DATE_UPDATED, { date });

/**
 *
 */
export const setQuestionnaireIndex = (questionIndexId: string) =>
	action(types.SET_QUESTIONNAIRE_INDEX, { questionIndexId });

/**
 *
 */
export const setModifiedBy = (userName: string) => action(types.SET_MODIFIED_BY, { userName });

/**
 *
 */
export const setFlagLabel = (flagLabel: QuestionFlagLabel) => action(types.SET_FLAG_LABEL, { flagLabel });

/**
 *
 */
export const setCategoryLabelFlags = (categoryLabelFlag: QuestionCategoryFlagLabel) =>
	action(types.SET_CATEGORY_LABEL_FLAGS, { categoryLabelFlag });

/**
 *
 */
export const setQuestionnaireDepartments = (data: string[]) => action(types.SET_QUESTIONNAIRE_DEPARTMENTS, { data });

/**
 *
 */
export const setQuestionnaireDeptLabel = (data: any[]) => action(types.SET_QUESTIONNAIRE_DEPT_LABEL, { data });

/**
 *
 */
export const showQuestionnaireAutoAssignmentModal = (modalType: string, selectedID: string, childQuestionIdx: string) =>
	action(types.SHOW_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL, { modalType, selectedID, childQuestionIdx });

/**
 *
 */
export const closeQuestionnaireAutoAssignmentModal = () => action(types.CLOSE_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL);

/**
 *
 */
export const SetApplyAllCategory = (categoryId: string) => action(types.SET_APPLY_ALL_CATEGORY, { categoryId });

/**
 *
 */
export const setAutoAssignment = (data: AutoAssignmentType) => action(types.SET_AUTO_ASSIGNMENT, { data });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for root question.
 * When enabled, user will be able to add rag to selected question.
 */
export const setAllowTag = (questionId: string) => action(types.SET_ALLOWED_TAG, { questionId });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for root question. When enabled,
 * user will be able to add tags to selected question.
 */
export const setAllowTagBatch = (allowedQuestions: AllowTagPool) =>
	action(types.SET_ALLOWED_TAG_BATCH, { allowedQuestions });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for conditional question.
 * When enabled, user will be able to add tags to selected question.
 */
export const setAllowConditionalTag = (parentId: string, condition: string, childId: number) =>
	action(types.SET_ALLOWED_CONDITIONAL_TAG, { parentId, condition, childId });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for conditional question. When
 * enabled, user will be able to add tags to selected question.
 */
export const setAllowConditionalTagBatch = (allowedQuestions: AllowConditionalTagPool) =>
	action(types.SET_ALLOWED_CONDITIONAL_TAG_BATCH, { allowedQuestions });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for root question.
 * When enabled, user will be able to add reference to selected question.
 */
export const setAllowReference = (questionId: string) => action(types.SET_ALLOWED_REFERENCE, { questionId });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for root question. When enabled,
 * user will be able to add reference to selected question.
 */
export const setAllowReferenceBatch = (allowedQuestions: AllowReferencePool) =>
	action(types.SET_ALLOWED_REFERENCE_BATCH, { allowedQuestions });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for conditional question.
 * When enabled, user will be able to add reference to selected question.
 */
export const setAllowConditionalReference = (parentId: string, condition: string, childId: number) =>
	action(types.SET_ALLOWED_CONDITIONAL_REFERENCE, { parentId, condition, childId });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for conditional question. When
 * enabled, user will be able to add reference to selected question.
 */
export const setAllowConditionalReferenceBatch = (allowedQuestions: AllowConditionalReferencePool) =>
	action(types.SET_ALLOWED_CONDITIONAL_REFERENCE_BATCH, { allowedQuestions });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for root question.
 * When enabled, user will be able to add recommendation to selected question.
 */
export const setAllowRecommendation = (questionId: string) => action(types.SET_ALLOWED_RECOMMENDATION, { questionId });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for conditional question.
 * When enabled, user will be able to add recommendation to selected question.
 */
export const setAllowConditionalRecommendation = (parentId: string, condition: string, childId: number) =>
	action(types.SET_ALLOWED_CONDITIONAL_RECOMMENDATION, { parentId, condition, childId });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for root question. When
 * enabled, user will be able to add recommendation to selected question.
 */
export const setAllowRecommendationBatch = (allowedQuestions: AllowRecommendationPool) =>
	action(types.SET_ALLOWED_RECOMMENDATION_BATCH, { allowedQuestions });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for conditional question. When
 * enabled, user will be able to add recommendation to selected question.
 */
export const setAllowConditionalRecommendationBatch = (allowedQuestions: AllowConditionalRecommendationPool) =>
	action(types.SET_ALLOWED_CONDITIONAL_RECOMMENDATION_BATCH, { allowedQuestions });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for root question.
 * When enabled, user will be able to add recommendation to selected question.
 */
export const setAllowAttachment = (questionId: string) => action(types.SET_ALLOWED_ATTACHMENT, { questionId });

/**
 * USE THIS TO CHANGE VALUE OF SPECIFIC OBJECT. Contain structure for conditional question.
 * When enabled, user will be able to add recommendation to selected question.
 */
export const setAllowConditionalAttachment = (parentId: string, condition: string, childId: number) =>
	action(types.SET_ALLOWED_CONDITIONAL_ATTACHMENT, { parentId, condition, childId });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for root question. When
 * enabled, user will be able to add recommendation to selected question.
 */
export const setAllowAttachmentBatch = (allowedQuestions: AllowAttachmentPool) =>
	action(types.SET_ALLOWED_ATTACHMENT_BATCH, { allowedQuestions });

/**
 * USE THIS TO RE-WRITE THE WHOLE OBJECT. Contain structure for conditional question. When
 * enabled, user will be able to add recommendation to selected question.
 */
export const setAllowConditionalAttachmentBatch = (allowedQuestions: AllowConditionalAttachmentPool) =>
	action(types.SET_ALLOWED_CONDITIONAL_ATTACHMENT_BATCH, { allowedQuestions });

/**
 *
 */
export const setIsBusy = (isBusy: boolean) => action(types.SET_IS_BUSY, { isBusy });

/**
 *
 */
export const setIsPageLoaded = (isLoaded: boolean) => action(types.SET_IS_PAGE_LOADED, { isLoaded });

/**
 * Auto Assignment V2
 */
export const setAutoAssignmentV2 = (data: AutoAssignmentTypeV2) => action(types.SET_AUTO_ASSIGNMENTV2, { data });

export const setPassCriteria = (data: string) => action(types.SET_PASS_CRITERIA, { data });

export const setPassCriteriaValue = (data: number | undefined) => action(types.SET_PASS_CRITERIA_VALUE, { data });

export const setPassCriteriaMinMaxValue = (data: { lowerBound: number; upperBound: number }) =>
	action(types.SET_PASS_CRITERIA_MIN_MAX_VALUE, { data });

export const setPassCriteriaType = (data: string) => action(types.SET_PASS_CRITERIA_TYPE, { data });

export const setUseDeductionToggle = (data: boolean) => action(types.SET_USE_DEDUCTIONS, { data });

export const setMaxScore = (data: number | undefined) => action(types.SET_MAX_SCORE, { data });

export const setIndependentIssue = (data: boolean) => action(types.SET_INDEPENDENT_ISSUE, { data });
export const setIndependentIssueQuestionnaire = (data: boolean) =>
	action(types.SET_INDEPENDENT_ISSUE_QUESTIONNAIRE, { data });

export const setShowCatAttrModal = (data: boolean) => action(types.SHOW_CATEGORY_ATTRIBUTE_MODAL, { data });

export const setCategoryAttributes = (data: CategoryAttributes[]) => action(types.UPDATE_CATEGORY_ATTRIBUTES, { data });
