import React, { useState } from 'react';
import MultiSelect, { MultiSelectProps } from '../Select/MultiSelect/MultiSelect';
import { useTranslation } from 'react-i18next';
import { InputActionMeta, OptionTypeBase } from 'react-select/src/types';
import { multiSelectFilterStyles } from '../../../utils/reactselectstyles';
import { Styles } from 'core/presentation/ui/Select/ReactSelectExtended';

export type FilterFieldProps<T> = Pick<
	MultiSelectProps<T>,
	'name' | 'value' | 'options' | 'onChange' | 'isDisabled' | 'disableSelectAll'
> & {
	emptyPlaceholder: string;
	filledPlaceholder: string;
	closeMenuOnSelect?: boolean;
	dimPlaceholder?: boolean;
	isLoading?: boolean;
	multiSelectStyles?: Partial<Styles>;
};

const FilterField = ({
	filledPlaceholder,
	emptyPlaceholder,
	multiSelectStyles,
	...props
}: FilterFieldProps<OptionTypeBase>) => {
	const { t } = useTranslation();
	const dimPlaceholder = props.dimPlaceholder ? props.value?.length === 0 : false;
	const [inputValue, setInputValue] = useState<string>('');

	const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
		if (actionMeta.action !== 'set-value') {
			setInputValue(newValue);
		}
	};

	const render = () => {
		return (
			<MultiSelect
				styles={multiSelectStyles ?? multiSelectFilterStyles(false, props.isDisabled || dimPlaceholder)}
				placeholder={props.value?.length > 0 ? `${filledPlaceholder} (${props.value.length})` : emptyPlaceholder}
				isSearchable
				disableSelectAll={props.disableSelectAll}
				selectCheckboxLabel={t('label.selectAll')}
				deselectCheckboxLabel={t('label.deselectAll')}
				controlShouldRenderValue={false}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				{...props}
			/>
		);
	};

	return render();
};

export default FilterField;
