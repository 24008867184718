import moment from 'moment';
import { DrillByEnum, GroupByEnum } from '@nimbly-technologies/nimbly-common/lib/enumerators';
import { Tab } from 'components/dashboardRevamp/TabButtons/TabButtons';
import { ColumnType, ColumnsConfig } from 'components/dashboardRevamp/ReportPreviewModal/ReportPreviewUtils';
import { OperationalFilters } from 'reducers/dashboardRevamp/operational/operationalStore';
import { IssueInsightsFilters } from 'reducers/dashboardRevamp/issueInsights/issueInsightsStore';
import { ENTITY_FIELD_NAMES } from 'components/dashboardRevamp/ViewDetailsModal/ViewDetailsModalUtils';
import { store } from 'store/configStore';
import { IssuePriorityType } from './DashboardTypes';

export enum GlobalFilter {
	StartDate = 'startDate',
	EndDate = 'endDate',
	SiteIDs = 'siteIDs',
	DepartmentIDs = 'departmentIDs',
	UserIDs = 'userIDs',
	QuestionnaireIDs = 'questionnaireIDs',
	Categories = 'categories',
}

export enum IssuesGlobalFilter {
	SecondaryStatus = 'secondaryStatus',
	Priority = 'priority',
	IssueIDs = 'issueIDs',
	IssueSource = 'issueSource',
	PrimaryStatus = 'primaryStatus',
}

export enum ViewBy {
	Site = 'site',
	Department = 'department',
	Questionnaire = 'questionnaire',
	Category = 'category',
	User = 'user',
}

export enum DrillBy {
	Site = 'site',
	Department = 'department',
	User = 'user',
	Category = 'category',
	Questionnaire = 'questionnaire',
}

export enum ViewDetailsGroupBy {
	Site = 'Site',
	Department = 'Department',
	User = 'User',
	Category = 'Category',
	Questionnaire = 'Questionnaire',
}

export enum ViewDetailsGroupByExecutive {
	Site = 'site',
	Department = 'department',
	User = 'user',
	Category = 'category',
	Questionnaire = 'questionnaire',
}

export const VIEW_BY_OPTIONS = [
	{ value: GroupByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: GroupByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: GroupByEnum.QUESTIONNAIRE, label: 'label.dashboardRevamp.questionnaire' },
	{ value: GroupByEnum.CATEGORY, label: 'label.dashboardRevamp.category' },
	{ value: GroupByEnum.USER, label: 'label.dashboardRevamp.user' },
];

export const DRILL_VIEW_BY_OPTIONS: {
	value: GroupByEnum;
	label: string;
}[] = [
	{ value: GroupByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: GroupByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: GroupByEnum.USER, label: 'label.dashboardRevamp.executiveDashboard.assignedTo' },
	{ value: GroupByEnum.CATEGORY, label: 'label.dashboardRevamp.category' },
];

export const DRILL_VIEW_BY_OPTIONS_REPORT: {
	value: GroupByEnum;
	label: string;
}[] = [
	{ value: GroupByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: GroupByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: GroupByEnum.USER, label: 'label.dashboardRevamp.executiveDashboard.userName' },
	{ value: GroupByEnum.QUESTIONNAIRE, label: 'label.dashboardRevamp.questionnaire' },
];

export const ISSUES_GROUP_BY_OPTIONS: {
	value: GroupByEnum | string;
	label: string;
}[] = [
	{ value: GroupByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: GroupByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: GroupByEnum.USER, label: 'label.dashboardRevamp.user' },
	{ value: GroupByEnum.CATEGORY, label: 'label.dashboardRevamp.category' },
	{ value: GroupByEnum.QUESTIONNAIRE, label: 'label.dashboardRevamp.questionnaire' },
	{ value: 'siteGroup', label: 'label.dashboardRevamp.executiveDashboard.siteGroup' },
];

export const REPORTS_GROUP_BY_OPTIONS: {
	value: GroupByEnum | string;
	label: string;
}[] = [
	{ value: GroupByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: GroupByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: GroupByEnum.USER, label: 'label.dashboardRevamp.user' },
	{ value: GroupByEnum.QUESTIONNAIRE, label: 'label.dashboardRevamp.questionnaire' },
	{ value: 'siteGroup', label: 'label.dashboardRevamp.executiveDashboard.siteGroup' },
];

export const REPORTS_DRILL_BY_OPTIONS = [
	{ value: DrillByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: DrillByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: DrillByEnum.USER, label: 'label.dashboardRevamp.user' },
	{ value: DrillByEnum.QUESTIONNAIRE, label: 'label.dashboardRevamp.questionnaire' },
];

export const DRILL_BY_OPTIONS: {
	value: DrillByEnum;
	label: string;
}[] = [
	{ value: DrillByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: DrillByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: DrillByEnum.USER, label: 'label.dashboardRevamp.executiveDashboard.assignedTo' },
	{ value: DrillByEnum.CATEGORY, label: 'label.dashboardRevamp.category' },
	{ value: DrillByEnum.QUESTIONNAIRE, label: 'label.dashboardRevamp.questionnaire' },
];

export const DRILL_BY_OPTIONS_V2 = [
	{ value: DrillByEnum.SITE, label: 'label.dashboardRevamp.site' },
	{ value: DrillByEnum.DEPARTMENT, label: 'label.dashboardRevamp.department' },
	{ value: DrillByEnum.USER, label: 'label.dashboardRevamp.user' },
	{ value: DrillByEnum.CATEGORY, label: 'label.dashboardRevamp.category' },
	{ value: DrillByEnum.QUESTIONNAIRE, label: 'label.dashboardRevamp.questionnaire' },
];

export enum IssueReportTabs {
	Issues = 'Issues',
	Reports = 'Reports',
}

export const IssueReportTabsOptions: Tab[] = [
	{ value: IssueReportTabs.Issues, label: 'label.dashboardRevamp.issues' },
	{ value: IssueReportTabs.Reports, label: 'label.dashboardRevamp.reports' },
];

export enum TableTabs {
	IssueCount = 'openIssueCount',
	IssueResolutionTime = 'irrTime',
	ReportCount = 'Report Count',
	ReportCompletionTime = 'Report Completion Time',
}

export const IssueTableTabOptions: Tab[] = [
	{
		label: 'label.dashboardRevamp.issueInsights.issueCount',
		value: TableTabs.IssueCount,
	},
	{
		label: 'label.dashboardRevamp.issueInsights.issueResolutionTime',
		value: TableTabs.IssueResolutionTime,
	},
];

export const ReportTableTabOptions: Tab[] = [
	{
		label: 'label.dashboardRevamp.reportInsights.reportCount',
		value: TableTabs.ReportCount,
	},
	{
		label: 'label.dashboardRevamp.reportInsights.reportCompletionTime',
		value: TableTabs.ReportCompletionTime,
	},
];

export interface NodeData {
	drilledData?: NodeData[];
	_id: string;
	[key: string]: any;
}

export interface IssueNodeData {
	[key: string]: any;
}

export enum RowTrim {
	Five = 5,
	Ten = 10,
}

export const RowTrimOptions = [
	{
		value: RowTrim.Five,
		label: '5 Rows',
	},
	{
		value: RowTrim.Ten,
		label: '10 Rows',
	},
	// {
	// 	value: RowTrim.All,
	// 	label: 'All Rows',
	// },
];

export const REPORT_INSIGHTS_COUNT_TABLE_CONFIGURATION = [
	{
		configurationLabel: 'label.dashboardRevamp.reportInsights.totalScheduled',
		isSelected: true,
		isDisabled: false,
		subColumns: [
			{
				fieldName: 'completed',
				columnLabel: 'label.dashboardRevamp.reportInsights.completedReports',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'pending',
				columnLabel: 'label.dashboardRevamp.reportInsights.pendingReports',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'missed',
				columnLabel: 'label.dashboardRevamp.reportInsights.missedReports',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
		],
	},
	{
		fieldName: 'adhoc',
		configurationLabel: 'label.dashboardRevamp.reportInsights.adhocReports',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
	},
	{
		fieldName: 'total',
		configurationLabel: 'label.dashboardRevamp.reportInsights.totalReports',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
	},
];

export const REPORT_INSIGHTS_RCR_TABLE_CONFIGURATION = [
	{
		fieldName: 'avgCompletionTime',
		configurationLabel: 'label.dashboardRevamp.reportInsights.average',
		isSelected: true,
		isDisabled: false,
		rowClassName: 'aa-text-center',
		type: ColumnType.Time,
	},
	{
		fieldName: 'maxCompletionTime',
		configurationLabel: 'label.dashboardRevamp.reportInsights.maximum',
		isSelected: true,
		isDisabled: false,
		rowClassName: 'aa-text-center',
		type: ColumnType.Time,
	},
	{
		fieldName: 'minCompletionTime',
		configurationLabel: 'label.dashboardRevamp.reportInsights.minimum',
		isSelected: true,
		isDisabled: false,
		rowClassName: 'aa-text-center',
		type: ColumnType.Time,
	},
];

export interface SiteI {
	siteID: string;
	name: string;
	country: string;
	province: string;
	city: string;
	locationName: string;
	address?: string;
	coordinates: {
		latitude: number;
		longitude: number;
	};
}

export interface MapStatisticsI {
	_id: string;
	redFlags: number;
	site: SiteI;
}

export type DashboardSiteMarker = SiteI & { issueCount?: number };

export const convertedCompletionTime = (time: number) => {
	// RCR - All completion times are passed in milliseconds
	if (time >= 60000) {
		// time >= 1 minute
		const minutes = Math.trunc(time / 60000);
		const hours = Math.trunc(minutes / 60);
		const remainingMinutes = minutes % 60;

		if (time > 86400000) {
			// time > 1 day
			const days = Math.trunc(hours / 24);
			const remainingHours = hours % 24;
			if (days && remainingHours === 0) {
				return `${days} days`;
			} else {
				return `${days} days ${remainingHours} hours`;
			}
		} else {
			if (remainingMinutes < 60 && hours === 0) {
				// 0 hours x minutes
				return `${remainingMinutes} minutes`;
			} else if (hours && remainingMinutes === 0) {
				// x hours 0 minutes
				return `${hours} hours`;
			} else {
				return `${hours} hours ${remainingMinutes} minutes`;
			}
		}
	} else if (time < 60000) {
		// time < 1 minute
		const seconds = Math.trunc(time / 1000);
		// hotfix for 0 seconds in report insights > report completion time tab
		if (seconds === 0) return '';
		if (seconds < 60) {
			return `${seconds} seconds`;
		} else {
			const minutes = Math.trunc(seconds / 60);
			const remainingSeconds = seconds % 60;
			return `${minutes} minutes ${remainingSeconds} seconds`;
		}
	}
};
// eslint-disable-next-line complexity
export function generateIssueApiQueryParams(
	globalFilters?: Record<string, any> | OperationalFilters | IssueInsightsFilters,
	excludedGlobalFilters: (GlobalFilter | IssuesGlobalFilter)[] = [],
) {
	const queryParams: Record<string, any> = {};
	if (globalFilters) {
		const {
			startDate,
			endDate,
			siteIDs,
			departmentIDs,
			userIDs,
			questionnaireIDs,
			categories,
			// @ts-ignore
			issueIDs,
		} = globalFilters;

		if (startDate && !excludedGlobalFilters?.includes(GlobalFilter.StartDate)) {
			queryParams['createdAt'] = queryParams['createdAt'] || {};
			queryParams['createdAt'].startDate = moment(startDate, 'YYYY-MM-DD').startOf('day').toDate();
			queryParams['qStartDate'] = startDate;
		}

		if (endDate && !excludedGlobalFilters?.includes(GlobalFilter.EndDate)) {
			queryParams['createdAt'] = queryParams['createdAt'] || {};
			queryParams['createdAt'].endDate = moment(endDate, 'YYYY-MM-DD').endOf('day').toDate();
			queryParams['qEndDate'] = endDate;
		}

		if (siteIDs && !excludedGlobalFilters?.includes(GlobalFilter.SiteIDs)) {
			queryParams['qSite'] = siteIDs;
		}

		if (departmentIDs && !excludedGlobalFilters?.includes(GlobalFilter.DepartmentIDs)) {
			queryParams['qDepartment'] = departmentIDs;
		}

		if (userIDs && !excludedGlobalFilters?.includes(GlobalFilter.UserIDs)) {
			queryParams['qUser'] = userIDs;
		}

		if (questionnaireIDs && !excludedGlobalFilters?.includes(GlobalFilter.QuestionnaireIDs)) {
			queryParams['qQuestionnaire'] = questionnaireIDs;
		}

		if (categories && !excludedGlobalFilters?.includes(GlobalFilter.Categories)) {
			const overallCategories = store.getState().admin.manage.questionCategories_data;

			let filteredQuesCat = overallCategories.filter(
				(cat) => cat?._id && (categories?.includes(cat?.value) || categories?.includes(cat?._id)),
			);

			// filtered ques and issue cat names
			let filteredQuesCatID = filteredQuesCat?.map((cat) => cat?._id) || [];
			let filteredQuesCatName = filteredQuesCat?.map((cat) => cat?.value) || [];
			let issueCatName: string[] =
				categories?.filter(
					(cat: string) => !(filteredQuesCatID?.includes(cat) || filteredQuesCatName?.includes(cat)),
				) || [];

			let categoriesName: string[] = [...filteredQuesCatName, ...issueCatName];

			categoriesName?.forEach((categoryName: string, index: number) => {
				queryParams[`qCategory`] = categoryName;
			});
		}

		if (issueIDs && !excludedGlobalFilters?.includes(IssuesGlobalFilter.IssueIDs)) {
			queryParams['qIssueIds'] = issueIDs;
		}

		if ('secondaryStatus' in globalFilters && !excludedGlobalFilters?.includes(IssuesGlobalFilter.SecondaryStatus)) {
			queryParams['qSecStatus'] = globalFilters.secondaryStatus;
		}

		if ('priority' in globalFilters && !excludedGlobalFilters?.includes(IssuesGlobalFilter.Priority)) {
			queryParams['qPriorityV2'] = globalFilters.priority
				.map((value: string) => formatPriorityValue(value))
				.filter(Boolean);
		}

		if ('issueSource' in globalFilters && !excludedGlobalFilters?.includes(IssuesGlobalFilter.IssueSource)) {
			queryParams['qIssueType'] = globalFilters.issueSource;
		}

		if ('primaryStatus' in globalFilters && !excludedGlobalFilters?.includes(IssuesGlobalFilter.PrimaryStatus)) {
			queryParams['qStatus'] = globalFilters.primaryStatus;
		}
		return queryParams;
	}
	return;
}

function formatPriorityValue(value: string): string | null {
	switch (value) {
		case IssuePriorityType.LOW:
			return 'p2';
		case IssuePriorityType.MEDIUM:
			return 'p1';
		case IssuePriorityType.HIGH:
			return 'p0';
		default:
			return null;
	}
}

export const DEFAULT_VIEW_BY = GroupByEnum.SITE;
export const DEFAULT_ROW_LIMIT = 5;
export const DEFAULT_PAGE_NUMBER = 1;
export const REPORT_SUMMARY_INITIAL_DATA = {
	avgCompletionTime: 0,
	highestMissedDepartment: '',
	maxCompletionTime: 0,
	minCompletionTime: 0,
	missedLastPeriodPercentage: 0,
	missedToday: 0,
	missedTrend: '',
	submittedToday: 0,
};

export enum ReportsDrilledIntoType {
	SubmittedToday = 'SubmittedToday',
	MissedToday = 'MissedToday',
}

export const REPORT_DOWNLOAD_VIEW_INIT_FIELDS = [
	'siteName',
	'adhoc',
	'totalSites',
	'city',
	'departmentName',
	'totalDepartments',
	'questionnaireName',
	'totalQuestionnaires',
	'userName',
	'totalUsers',
	'totalSchedules',
	'avgCompletionTime',
	'minCompletionTime',
	'maxCompletionTime',
	'avgCompletionRate',
];

export const ISSUE_DOWNLOAD_VIEW_INIT_FIELDS = [
	'siteName',
	'totalSites',
	'totalDepartments',
	'totalQuestionnaires',
	'totalUsers',
	'totalCategories',
	'totalIssues',
	'totalQuestions',
	'totalGreenFlags',
	'totalYellowFlags',
	'totalRedFlags',
	'city',
	'departmentName',
	'questionnaireName',
	'userName',
	'avgIrr',
	'avgIrt',
	'closedIssues',
	'openIssues',
	'attachments',
];

export const REPORT_DOWNLOAD_COLUMN_CONFIG_LIST: string[] = [
	'siteName',
	'totalSites',
	'city',
	'totalDepartments',
	'totalQuestionnaires',
	'totalUsers',
	'totalSchedules',
	'avgCompletionTime',
	'minCompletionTime',
	'maxCompletionTime',
	'avgCompletionRate',
	'adhoc',
	'departmentName',
	'questionnaireName',
	'userName',
	'total',
	'completed',
	'pending',
	'missed',
];

export enum KpiType {
	IssueResolutionRate = 'IssueResolutionRate',
	ReportCompletionRate = 'ReportCompletionRate',
}

export enum TrendsType {
	Cumulative = 'Cumulative',
	ByStatus = 'ByStatus',
}

export interface TrendsIssueData {
	resolved: number;
	date: string;
	open: number;
	inProgress: number;
	inReview: number;
	blocked: number;
	total: number;
}

export interface TrendsReportData {
	completed: number;
	date: string;
	incomplete: number;
	total: number;
}

export const DEFAULT_OPERATIONAL_PAGE_GLOBAL_FILTERS: OperationalFilters = {
	// Last 7 days by default
	startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
	endDate: moment().format('YYYY-MM-DD'),
	siteIDs: [],
	departmentIDs: [],
	userIDs: [],
	questionnaireIDs: [],
	categories: [],
};

export const LAST_MONTH_OPERATIONAL_PAGE_GLOBAL_FILTERS: OperationalFilters = {
	// Last 30 days
	startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
	endDate: moment().format('YYYY-MM-DD'),
	siteIDs: [],
	departmentIDs: [],
	userIDs: [],
	questionnaireIDs: [],
	categories: [],
};

export enum DashboardDataType {
	Issue = 'Issue',
	Report = 'Report',
}

export const DRILL_MODAL_DEFAULT_DRILL_BY_FIELDS = [DrillByEnum.DEPARTMENT];
export const DRILL_MODAL_DEFAULT_GROUP_BY = GroupByEnum.SITE;

export const columnConfigBank: ColumnsConfig[] = [
	{
		fieldName: 'siteName',
		configurationLabel: 'label.dashboardRevamp.issueInsights.siteName',
		columnLabel: 'label.dashboardRevamp.site',
		isSelected: true,
		isDisabled: true,
		rowClassName: 'aa-text-center',
	},
	{
		fieldName: 'totalSites',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.totalSites',
		isSelected: false,
		isDisabled: false,
		rowClassName: 'aa-text-center',
	},
	{
		fieldName: 'scoringBasedOn',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.scoringBasedOn',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.scoringBasedOn',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'severity',
		configurationLabel: 'label.dashboardRevamp.issueInsights.severity',
		columnLabel: 'label.dashboardRevamp.issueInsights.severity',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
		subColumns: [
			{
				fieldName: 'severityRed',
				columnLabel: 'Red',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'severityYellow',
				columnLabel: 'Yellow',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
		],
	},
	{
		fieldName: 'secondaryStatus',
		configurationLabel: 'Secondary Status',
		columnLabel: 'Secondary Status',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
		subColumns: [
			{
				fieldName: 'secondaryStatusOnTime',
				columnLabel: 'On Time',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'secondaryStatusLate',
				columnLabel: 'Late',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
		],
	},
	{
		fieldName: 'flag',
		configurationLabel: 'Flag',
		columnLabel: 'Flag',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
		subColumns: [
			{
				fieldName: 'flagGreen',
				columnLabel: 'label.dashboardRevamp.executiveDashboard.totalGreenFlags',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'flagYellow',
				columnLabel: 'label.dashboardRevamp.executiveDashboard.totalYellowFlags',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'flagRed',
				columnLabel: 'label.dashboardRevamp.executiveDashboard.totalRedFlags',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
		],
	},
	{
		fieldName: 'priority',
		configurationLabel: 'label.dashboardRevamp.issueInsights.priority',
		columnLabel: 'label.dashboardRevamp.issueInsights.priority',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
		subColumns: [
			{
				fieldName: 'priorityHigh',
				columnLabel: 'High',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'priorityMedium',
				columnLabel: 'Medium',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'priorityLow',
				columnLabel: 'Low',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
		],
	},
	{
		fieldName: 'openIssue',
		configurationLabel: 'Open Issue',
		isSelected: true,
		isDisabled: false,
		subColumns: [
			{
				fieldName: 'openIssueRed',
				columnLabel: 'Red',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'openIssueYellow',
				columnLabel: 'Yellow',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
		],
	},
	{
		fieldName: 'totalIssue',
		configurationLabel: 'label.dashboardRevamp.issueInsights.totalIssue',
		columnLabel: 'label.dashboardRevamp.issueInsights.totalIssue',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'avgIssueResolutionRate',
		configurationLabel: 'label.dashboardRevamp.operationalPage.AvgIRR',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Percentage,
	},
	{
		fieldName: 'avgIssueResolutionTime',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.avgIRT',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.avgIRT',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
	},
	{
		fieldName: 'maxIssueResolutionTime',
		configurationLabel: 'Max Issue Resolution Time',
		columnLabel: 'Max',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
	},
	{
		fieldName: 'minIssueResolutionTime',
		configurationLabel: 'Min Issue Resolution Time',
		columnLabel: 'Min',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
	},
	{
		fieldName: 'attachments',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.attachments',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.attachments',
		isSelected: false,
		isDisabled: false,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'closedIssue',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.totalClosedIssues',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.totalClosedIssues',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'categoryName',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.ctgName',
		columnLabel: 'label.dashboardRevamp.category',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
		rowClassName: 'aa-text-center',
	},
	{
		fieldName: 'city',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.city',
		isSelected: false,
		isDisabled: false,
		rowClassName: 'aa-text-center',
	},
	{
		fieldName: 'departmentName',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.deptName',
		columnLabel: 'label.dashboardRevamp.department',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[150px] aa-max-w-[150px]',
	},
	{
		fieldName: 'questionnaireName',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.questionnaireName',
		columnLabel: 'label.dashboardRevamp.questionnaire',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
		headerClassName: 'aa-min-w-[200px] aa-max-w-[200px] [overflow-wrap:anywhere] aa-text-center',
		rowClassName: 'aa-text-center',
	},
	{
		fieldName: 'totalCategories',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.totalCategories',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.totalCategories',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'totalDepartments',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.totalDepts',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.totalDepts',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[150px] aa-max-w-[150px]',
	},
	{
		fieldName: 'totalQuestionnaires',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.totalQuestionnaires',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.totalQuestionnaires',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[150px] aa-max-w-[150px]',
	},
	{
		fieldName: 'totalQuestions',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.totalQuestions',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.totalQuestions',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'totalUsers',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.totalUsers',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.totalUsers',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'date',
		configurationLabel: 'Date',
		columnLabel: 'Date',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'userName',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.userName',
		columnLabel: 'label.dashboardRevamp.user',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Text,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	// Report extra columns
	{
		fieldName: 'totalSchedules',
		configurationLabel: 'label.dashboardRevamp.reportInsights.totalSchedules',
		columnLabel: 'label.dashboardRevamp.reportInsights.totalSchedules',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
		subColumns: [
			{
				fieldName: 'completed',
				columnLabel: 'label.dashboardRevamp.completedReports',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'pending',
				columnLabel: 'label.dashboardRevamp.reportInsights.pendingReports',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
			{
				fieldName: 'missed',
				columnLabel: 'label.dashboardRevamp.reportInsights.missed',
				type: ColumnType.Number,
				headerClassName: 'aa-min-w-[120px] aa-max-w-[120px]',
			},
		],
	},
	{
		fieldName: 'totalReports',
		configurationLabel: 'label.dashboardRevamp.total',
		columnLabel: 'label.dashboardRevamp.total',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'adhoc',
		configurationLabel: 'label.dashboardRevamp.reportInsights.adhoc',
		columnLabel: 'label.dashboardRevamp.reportInsights.adhoc',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Number,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'avgReportCompletionRate',
		configurationLabel: 'label.dashboardRevamp.operationalPage.RCR',
		columnLabel: 'label.dashboardRevamp.operationalPage.RCR',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Percentage,
		headerClassName: 'aa-min-w-[150px] aa-max-w-[150px]',
	},
	{
		fieldName: 'avgReportCompletionTime',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.avgCompletionTime',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.avgCompletionTime',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Text,
		headerClassName: 'aa-min-w-[150px] aa-max-w-[150px]',
	},
	{
		fieldName: 'maxReportCompletionTime',
		configurationLabel: 'Max Completion Time',
		columnLabel: 'Max Report Completion Time',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
	},
	{
		fieldName: 'minReportCompletionTime',
		configurationLabel: 'Min Completion Time',
		columnLabel: 'Min Report Completion Time',
		isSelected: false,
		isDisabled: false,
		type: ColumnType.Text,
	},
	{
		fieldName: 'maxCompletionTime',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.maxCompletionTime',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.maxCompletionTime',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Text,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
	{
		fieldName: 'minCompletionTime',
		configurationLabel: 'label.dashboardRevamp.executiveDashboard.minCompletionTime',
		columnLabel: 'label.dashboardRevamp.executiveDashboard.minCompletionTime',
		isSelected: true,
		isDisabled: false,
		type: ColumnType.Text,
		headerClassName: 'aa-min-w-[100px] aa-max-w-[100px]',
	},
];

export function getFormattedValue(format?: ColumnType, value: any = '') {
	switch (format) {
		case ColumnType.Percentage: {
			return typeof value === 'number' ? `${value.toFixed(2)}%` : 0;
		}
		default:
			return value;
	}
}

export enum DashboardTabs {
	Issues = 'Issues',
	Reports = 'Reports',
	QC = 'QC',
}

export const DashboardTabsOptions: Tab[] = [
	{ value: DashboardTabs.Issues, label: 'label.dashboardRevamp.issues' },
	{ value: DashboardTabs.Reports, label: 'label.dashboardRevamp.reports' },
	{ value: DashboardTabs.QC, label: 'label.dashboardRevamp.QC' },
];

export const DashboardTabsOptionsMobile: Tab[] = [
	{ value: DashboardTabs.Issues, label: 'label.dashboardRevamp.issues' },
	{ value: DashboardTabs.Reports, label: 'label.dashboardRevamp.reports' },
];
export const DRILL_BY_TO_FIELDNAME = {
	[DrillByEnum.CATEGORY]: 'categoryName',
	[DrillByEnum.DEPARTMENT]: 'departmentName',
	[DrillByEnum.QUESTIONNAIRE]: 'questionnaireName',
	[DrillByEnum.SITE]: 'siteName',
	[DrillByEnum.USER]: 'userName',
	[DrillByEnum.REPORTED_USER]: 'userName',
	[DrillByEnum.SITE_GROUP]: 'siteGroupName',
};

export const DRILL_BY_REPORTS_ORDER = [
	DrillByEnum.SITE,
	DrillByEnum.DEPARTMENT,
	DrillByEnum.USER,
	DrillByEnum.QUESTIONNAIRE,
];

export enum DashboardDataType {
	TotalIssue = 'totalIssue',
	ResolvedIssue = 'resolvedIssue',
	OpenIssue = 'openIssue',
}

export enum TrendType {
	Up = 'up',
	Down = 'down',
	Stable = 'stable',
}

export const dataTrendCalc = (a: number, b: number) => {
	// used in Basic Dashboard
	if (a > b) {
		return TrendType.Up;
	} else if (a < b) {
		return TrendType.Down;
	} else {
		return TrendType.Stable;
	}
};
