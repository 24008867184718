import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { queryClient } from './client';

interface QueryClientProviderProps {
	children: React.ReactNode;
}

export const ReactQueryClientProvider = (props: QueryClientProviderProps) => {
	const { children } = props;

	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
