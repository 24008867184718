/* eslint-disable complexity */
// @ts-nocheck

import { ActionType } from 'typesafe-actions';
import { QuestionnaireDetailState, AutoAssignmentType, AutoAssignmentTypeV2 } from './types';
import * as actions from './questionnaireDetail.action';
import * as types from './questionnaireDetail.actionTypes';
import * as Common from 'nimbly-common';

import { QuestionTypes } from 'nimbly-common/lib/enumerators';

export type QuestionnaireDetailAction = ActionType<typeof actions>;

const initialState: QuestionnaireDetailState = {
	categories: {
		'category-0': {
			id: 'category-0',
			title: '',
			selectValue: {
				label: '',
				value: '',
			},
			questionIds: ['question-0'],
		},
	},
	categoryIds: ['category-0'],
	videoSet: 0,
	questionWithVideos: 0,
	questionWithScore: 0,
	totalScoreWeight: 0,
	editingQuestions: { 'question-0': false },
	editingConditionalQuestions: { 'question-0': { 'red-flag': [false] } },
	allowTag: { 'question-0': false },
	allowConditionalTag: { 'question-0': { 'red-flag': [false] } },
	allowAttachment: { 'question-0': true },
	allowConditionalAttachment: { 'question-0': { 'red-flag': [true] } },
	allowReference: { 'question-0': false },
	allowConditionalReference: { 'question-0': { 'red-flag': [false] } },
	allowRecommendation: { 'question-0': false },
	allowConditionalRecommendation: { 'question-0': { 'red-flag': [false] } },
	inventoryQuestions: {},
	inventoryIndex: -1,
	selectedRangeFlagType: {},
	isRangeFlagInverted: {},
	selectedConditionalRangeFlagType: {},
	isConditionalRangeFlagInverted: {},
	isBusy: false,
	isPageLoaded: false,
	checkField: false,
	title: '',
	questions: {
		'question-0': {
			...new Common.Question(
				QuestionTypes.BINARY,
				'',
				'',
				0,
				0,
				0,
				0,
				[],
				[],
				[],
				undefined,
				[],
				'',
				[],
				[],
				'',
				0,
				0,
				true,
				'',
				'',
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				'',
				'',
				'',
				null,
				undefined,
				new Common.ConditionalQuestion({}),
			),
		},
	},
	dateCreated: '',
	dateUpdated: '',
	isEditable: false,
	disabled: false,
	questionnaireIndex: '',
	modifiedBy: '',
	flagLabel: {
		'binary-with-red-flag': { green: '', yellow: '', red: '' },
		binary: { green: '', yellow: '', red: '' },
	},
	categoryLabelFlags: {
		'binary-with-red-flag': {},
		binary: {},
	},
	questionnaireDepartments: [],
	questionnaireDeptLabel: [],
	autoAssignmentModal: false,
	selectedCategory: '',
	applyAllcategoryID: '',
	autoAssignment: {},
	// @ts-ignore
	acrossDepartement: false,
	autoAssignmentV2: {
		questionnaireLevel: null,
		categoryLevel: [],
		questionLevel: [],
	},
	modalType: '',
	selectedID: '',
	questionIdx: 0,
	childQuestionIdx: '',
	showCategoryAttributeModal: false,
};

/**
 * This is the redux function of Questionnaire Detail. This redux is used to
 * handle data of a single question, opened through questionnaire list.
 *
 */

// //////////////////////////////////////////////////////////////////////////////////////////////////////////
// ////
// ////                              Guidelines for modifying this redux
// ////
// //////////////////////////////////////////////////////////////////////////////////////////////////////////
// ////
// ////  GENERAL
// ////
// ////  1 )   Make sure to match the INITIAL STATE content with case types.RESET_STATE
// ////        when you are trying to add new properties.
// ////
// ////  ps.   There is an odd behaviour that:
// ////        Calling the INITIAL STATE from reset state will not work, even though
// ////        data from the INITIAL STATE has been cloned to new variable. For now,
// ////        resetting state will be done manually.
// ////
// ////  2 )   If you encounter 'variable of type never' from the component, trying to
// ////        call properties within this redux, it can mean that you violate rule
// ////        number (1) above, or you have assign the property with the wrong data type.
// ////        Watch out when you are trying to set value on nested data type as it can
// ////        cause this error when it's wrong.
// ////
// ////  (Other documentation to be added later)
// ////
// //////////////////////////////////////////////////////////////////////////////////////////////////////////
export function questionnaireDetailReducer(state = initialState, action: QuestionnaireDetailAction) {
	switch (action.type) {
		case types.SET_ALLOWED_TAG:
			return {
				...state,
				allowTag: {
					...state.allowTag,
					[action.payload.questionId]: !state.allowTag[action.payload.questionId],
				},
			};
		case types.SET_ALLOWED_CONDITIONAL_TAG:
			return {
				...state,
				allowConditionalTag: {
					...state.allowConditionalTag,
					[action.payload.parentId]: {
						...state.allowConditionalTag[action.payload.parentId],
						[action.payload.condition]: {
							...state.allowConditionalTag[action.payload.parentId][action.payload.condition],
							[action.payload.childId]: !state.allowConditionalTag[action.payload.parentId][action.payload.condition][
								action.payload.childId
							],
						},
					},
				},
			};
		case types.SET_ALLOWED_TAG_BATCH:
			return {
				...state,
				allowTag: action.payload.allowedQuestions,
			};

		case types.SET_ALLOWED_CONDITIONAL_TAG_BATCH:
			return {
				...state,
				allowConditionalTag: action.payload.allowedQuestions,
			};

		case types.SET_ALLOWED_RECOMMENDATION:
			return {
				...state,
				allowRecommendation: {
					...state.allowRecommendation,
					[action.payload.questionId]: !state.allowRecommendation[action.payload.questionId],
				},
			};
		case types.SET_ALLOWED_RECOMMENDATION_BATCH:
			return {
				...state,
				allowRecommendation: action.payload.allowedQuestions,
			};
		case types.SET_ALLOWED_CONDITIONAL_RECOMMENDATION_BATCH:
			return {
				...state,
				allowConditionalRecommendation: action.payload.allowedQuestions,
			};
		case types.SET_ALLOWED_CONDITIONAL_RECOMMENDATION:
			return {
				...state,
				allowConditionalRecommendation: {
					...state.allowConditionalRecommendation,
					[action.payload.parentId]: {
						...state.allowConditionalRecommendation[action.payload.parentId],
						[action.payload.condition]: {
							...state.allowConditionalRecommendation[action.payload.parentId][action.payload.condition],
							[action.payload.childId]: !state.allowConditionalRecommendation[action.payload.parentId][
								action.payload.condition
							][action.payload.childId],
						},
					},
				},
			};

		case types.SET_ALLOWED_REFERENCE:
			return {
				...state,
				allowReference: {
					...state.allowReference,
					[action.payload.questionId]: !state.allowReference[action.payload.questionId],
				},
			};
		case types.SET_ALLOWED_REFERENCE_BATCH:
			return {
				...state,
				allowReference: action.payload.allowedQuestions,
			};
		case types.SET_ALLOWED_CONDITIONAL_REFERENCE:
			return {
				...state,
				allowConditionalReference: {
					...state.allowConditionalReference,
					[action.payload.parentId]: {
						...state.allowConditionalReference[action.payload.parentId],
						[action.payload.condition]: {
							...state.allowConditionalReference[action.payload.parentId][action.payload.condition],
							[action.payload.childId]: !state.allowConditionalReference[action.payload.parentId][
								action.payload.condition
							][action.payload.childId],
						},
					},
				},
			};
		case types.SET_ALLOWED_CONDITIONAL_REFERENCE_BATCH:
			return {
				...state,
				allowConditionalReference: action.payload.allowedQuestions,
			};

		case types.SET_ALLOWED_ATTACHMENT:
			return {
				...state,
				allowAttachment: {
					...state.allowAttachment,
					[action.payload.questionId]: !state.allowAttachment[action.payload.questionId],
				},
			};
		case types.SET_ALLOWED_ATTACHMENT_BATCH:
			return {
				...state,
				allowAttachment: action.payload.allowedQuestions,
			};
		case types.SET_ALLOWED_CONDITIONAL_ATTACHMENT:
			return {
				...state,
				allowConditionalAttachment: {
					...state.allowConditionalAttachment,
					[action.payload.parentId]: {
						...state.allowConditionalAttachment[action.payload.parentId],
						[action.payload.condition]: {
							...state.allowConditionalAttachment[action.payload.parentId][action.payload.condition],
							[action.payload.childId]: !state.allowConditionalAttachment[action.payload.parentId][
								action.payload.condition
							][action.payload.childId],
						},
					},
				},
			};
		case types.SET_ALLOWED_CONDITIONAL_ATTACHMENT_BATCH:
			return {
				...state,
				allowConditionalAttachment: action.payload.allowedQuestions,
			};

		case types.SET_IS_BUSY:
			return {
				...state,
				isBusy: action.payload.isBusy,
			};
		case types.SET_IS_PAGE_LOADED:
			return {
				...state,
				isPageLoaded: action.payload.isLoaded,
			};

		case types.RESET_STATE:
			return initialState;
		case types.RESET_STATE_CUSTOMER_FEEDBACK:
			// @ts-ignore
			delete initialState.title;

			return initialState;
		case types.SET_CATEGORIES:
			return {
				...state,
				categories: action.payload.categories,
			};
		case types.SET_CATEGORY_IDS:
			return {
				...state,
				categoryIds: action.payload.categoryIds,
			};
		case types.SET_VIDEO_SET:
			return {
				...state,
				videoSet: action.payload.total,
			};
		case types.SET_QUESTION_WITH_VIDEO:
			return {
				...state,
				questionWithVideos: action.payload.total,
			};
		case types.SET_QUESTION_WITH_SCORE:
			return {
				...state,
				questionWithScore: action.payload.total,
			};
		case types.SET_TOTAL_SCORE_WEIGHT:
			return {
				...state,
				totalScoreWeight: action.payload.total,
			};
		case types.SET_EDITING_QUESTIONS:
			return {
				...state,
				editingQuestions: action.payload.editingQuestions,
			};
		case types.SET_EDITING_CONDITIONAL_QUESTIONS:
			return {
				...state,
				editingConditionalQuestions: action.payload.editingQuestions,
			};
		case types.SET_INVENTORY_QUESTIONS:
			return {
				...state,
				inventoryQuestions: action.payload.inventoryQuestions,
			};
		case types.SET_INVENTORY_INDEX:
			return {
				...state,
				inventoryIndex: action.payload.inventoryIndex,
			};
		case types.SET_CHECK_FIELD:
			return {
				...state,
				checkField: action.payload.checking,
			};
		case types.SET_TITLE:
			return {
				...state,
				title: action.payload.title,
			};
		case types.SET_QUESTIONS: {
			const { questions } = action.payload;
			const nextQuestions: typeof questions = {};
			Object.entries(questions).forEach(([key, value]) => {
				nextQuestions[key] = {
					...value,
					checklistsV2:
						value.checklistsV2 ??
						value.checklists?.map((checklist) => ({
							label: checklist,
							hasRedFlag: false,
						})) ??
						[],
				};
			});

			return {
				...state,
				questions: nextQuestions,
			};
		}
		case types.SET_CONDITIONAL_QUESTIONS:
			return {
				...state,
				questions: {
					...state.questions,
					[action.payload.parentId]: {
						...state.questions[action.payload.parentId],
						conditionalQuestion: {
							...state.questions[action.payload.parentId].conditionalQuestion,
							conditions: {
								...state.questions[action.payload.parentId].conditionalQuestion?.conditions,
								[action.payload.condition]: action.payload.questions,
							},
						},
					},
				},
			};
		case types.SET_DATE_CREATED:
			return {
				...state,
				dateCreated: action.payload.date,
			};
		case types.SET_DATE_UPDATED:
			return {
				...state,
				dateUpdated: action.payload.date,
			};
		case types.SET_IS_EDITABLE:
			return {
				...state,
				isEditable: action.payload.isEditable,
			};
		case types.SET_QUESTIONNAIRE_INDEX:
			return {
				...state,
				questionnaireIndex: action.payload.questionIndexId,
			};
		case types.SET_MODIFIED_BY:
			return {
				...state,
				modifiedBy: action.payload.userName,
			};
		case types.SET_FLAG_LABEL:
			return {
				...state,
				flagLabel: action.payload.flagLabel,
			};
		case types.SET_CATEGORY_LABEL_FLAGS:
			return {
				...state,
				categoryLabelFlags: action.payload.categoryLabelFlag,
			};
		case types.SET_QUESTIONNAIRE_DEPARTMENTS:
			return {
				...state,
				questionnaireDepartments: action.payload.data,
			};
		case types.SET_QUESTIONNAIRE_DEPT_LABEL:
			return {
				...state,
				questionnaireDeptLabel: action.payload.data,
			};
		case types.SET_SELECTED_RANGE_FLAG_TYPE:
			return {
				...state,
				selectedRangeFlagType: {
					...state.selectedRangeFlagType,
					[action.payload.questionId]: action.payload.selectedRangeFlagType,
				},
			};
		case types.SET_IS_RANGE_FLAG_INVERTED_BATCH:
			return {
				...state,
				isRangeFlagInverted: action.payload.rangeFlagInverted,
			};
		case types.SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED_BATCH:
			return {
				...state,
				isConditionalRangeFlagInverted: action.payload.rangeFlagInverted,
			};
		case types.SET_IS_RANGE_FLAG_INVERTED:
			return {
				...state,
				isRangeFlagInverted: {
					...state.isRangeFlagInverted,
					[action.payload.questionId]: action.payload.inversed,
				},
			};
		case types.SET_IS_CONDITIONAL_RANGE_FLAG_INVERTED:
			return {
				...state,
				isConditionalRangeFlagInverted: {
					...state.isConditionalRangeFlagInverted,
					[action.payload.parentId]: {
						...state.isConditionalRangeFlagInverted[action.payload.parentId],
						[action.payload.condition]: {
							...state.isConditionalRangeFlagInverted[action.payload.parentId][action.payload.condition],
							[action.payload.childId]: action.payload.inversed,
						},
					},
				},
			};
		case types.SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE:
			return {
				...state,
				selectedConditionalRangeFlagType: {
					...state.selectedConditionalRangeFlagType,
					[action.payload.parentId]: {
						...state.selectedConditionalRangeFlagType[action.payload.parentId],
						[action.payload.condition]: {
							...state.selectedConditionalRangeFlagType[action.payload.parentId][action.payload.condition],
							[action.payload.childId]: action.payload.selectedRangeFlagType,
						},
					},
				},
			};
		case types.SET_SELECTED_RANGE_FLAG_TYPE_BATCH:
			return {
				...state,
				selectedRangeFlagType: action.payload.selectedRangeFlagType,
			};
		case types.SET_SELECTED_CONDITIONAL_RANGE_FLAG_TYPE_BATCH:
			return { ...state, selectedConditionalRangeFlagType: action.payload.selectedRangeFlagType };
		case types.SHOW_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL:
			return {
				...state,
				autoAssignmentModal: true,
				selectedID: action.payload.selectedID,
				selectedCategory: action.payload.selectedID,
				modalType: action.payload.modalType,
				...(Number(action.payload.selectedID) > -1 && { questionIdx: Number(action.payload.selectedID) }),
				...(action.payload.childQuestionIdx && {
					childQuestionIdx: action.payload.childQuestionIdx,
				}),
			};
		case types.CLOSE_QUESTIONNAIRE_AUTO_ASSIGNMENT_MODAL:
			return {
				...state,
				autoAssignmentModal: false,
				selectedCategory: '',
				selectedID: '',
				modalType: '',
				childQuestionIdx: '',
			};
		case types.SET_APPLY_ALL_CATEGORY:
			return {
				...state,
				applyAllcategoryID: action.payload.categoryId,
			};
		case types.SET_AUTO_ASSIGNMENT:
			return {
				...state,
				autoAssignment: action.payload.data,
			};
		case types.SET_AUTO_ASSIGNMENTV2:
			return {
				...state,
				autoAssignmentV2: action.payload.data,
			};
		case types.SET_PASS_CRITERIA:
			return {
				...state,
				passingCriteria: action.payload.data,
			};
		case types.SET_PASS_CRITERIA_VALUE:
			return {
				...state,
				passingValue: action.payload.data,
			};
		case types.SET_PASS_CRITERIA_MIN_MAX_VALUE:
			return {
				...state,
				passingValue: action.payload.data,
			};
		case types.SET_PASS_CRITERIA_TYPE:
			return {
				...state,
				passingType: action.payload.data,
			};
		case types.SET_USE_DEDUCTIONS:
			return {
				...state,
				deductionToggle: action.payload.data,
			};
		case types.SET_MAX_SCORE:
			return {
				...state,
				maxScore: action.payload.data,
			};
		case types.SET_INDEPENDENT_ISSUE:
			// eslint-disable-next-line
			let updatedQuestion = state.questions;
			Object.entries(updatedQuestion).forEach(([key, value]) => {
				updatedQuestion[key] = {
					...value,
					isIndependentIssue: action.payload.data,
				};
			});

			return {
				...state,
				isIndependentIssue: action.payload.data,
				questions: updatedQuestion,
			};
		case types.SET_INDEPENDENT_ISSUE_QUESTIONNAIRE:
			return {
				...state,
				isIndependentIssue: action.payload.data,
			};
		case types.SHOW_CATEGORY_ATTRIBUTE_MODAL:
			return {
				...state,
				showCategoryAttributeModal: action.payload.data,
			};
		case types.UPDATE_CATEGORY_ATTRIBUTES:
			return {
				...state,
				categoryAttributes: action.payload.data,
			};
		default:
			return state;
	}
}
