import { IssuePriorityTypeV2 as IssuePriorityType } from 'core/domain/models/issue/issue';

export enum IssueType {
	REPORT = 'report',
	REPORT_ADHOC = 'report-adhoc',
	REPORT_SCHEDULED = 'report-scheduled',
	MANUAL_ADDITION = 'manual-addition',
	CUSTOMER_FEEDBACK = 'customer-feedback',
}

export enum IssueFilterQuery {
	ISSUE_TYPE = 'qIssueType',
	QUESTIONNAIRE = 'qQuestionnaire',
	CF_QUESTIONNAIRE = 'qCFQuestionnaireID',
	USER = 'qUser',
	APPROVERS = 'qApprovers',
	DEPARTMENT = 'qDepartment',
	SITE = 'qSite',
	FLAG = 'qFlag',
	PRIORITY = 'qPriorityV2',
	STATUS = 'qStatus',
	SECONDARY_STATUS = 'qSecStatus',
	APPROVAL_STATUS = 'qApprovalStatus',
}

export enum IssueFlagType {
	RED = 'red',
	YELLOW = 'yellow',
}

export { IssuePriorityType };

export enum IssueStatusType {
	OPEN = 'open',
	RESOLVED = 'resolved',
	EXPIRED = 'expired',
	BLOCKED = 'blocked',
	IN_REVIEW = 'in-review',
	IN_PROGRESS = 'in-progress',
}

export enum IssueSecondaryStatusType {
	ON_TIME = 'on-time',
	BEHIND_TIME = 'behind-time',
	OVERDUE = 'overdue',
	DEFAULT = '',
}

export enum IssueApprovalStatusType {
	APPROVED = 'approved',
	REJECTED = 'rejected',
	PENDING = 'pending',
}
