export const SHOW_MODAL = 'issues/SHOW_IMAGE_MODAL';
export const SHOW_CREATE_ISSUE_MODAL = 'issues/SHOW_CREATE_ISSUE_MODAL';
export const DISMISS_MODAL = 'issues/DISMISS_IMAGE_MODAL';
export const SHOW_MODAL_MEMBER = 'issues/SHOW_MODAL_MEMBER';

/** Special actions for asynchronous events */
export const QUICK_RESOLVE_ISSUE_REQUEST = '@issues/QUICK_RESOLVE_ISSUE_REQUEST';
export const QUICK_RESOLVE_ISSUE_SUCCESS = '@issues/QUICK_RESOLVE_ISSUE_SUCCESS';
export const QUICK_RESOLVE_ISSUE_FAILURE = '@issues/QUICK_RESOLVE_ISSUE_FAILURE';

export const UPDATE_ISSUE_SINGLE_REQUEST = '@issues/UPDATE_ISSUE_SINGLE_REQUEST';
export const UPDATE_ISSUE_SINGLE_SUCCESS = '@issues/UPDATE_ISSUE_SINGLE_SUCCESS';
export const UPDATE_ISSUE_SINGLE_FAILURE = '@issues/UPDATE_ISSUE_SINGLE_FAILURE';

export const FETCH_ISSUE_BATCH_REQUEST = '@issues/FETCH_ISSUE_BATCH_REQUEST';
export const FETCH_ISSUE_BATCH_SUCCESS = '@issues/FETCH_ISSUE_BATCH_SUCCESS';
export const FETCH_ISSUE_BATCH_FAILURE = '@issues/FETCH_ISSUE_BATCH_FAILURE';
export const SET_ISSUES_DATA = '@issues/SET_ISSUES_DATA';

export const FETCH_ISSUE_CUSTOM_FILTERS_REQUEST = '@issues/FETCH_ISSUE_CUSTOM_FILTERS_REQUEST';
export const FETCH_ISSUE_CUSTOM_FILTERS_SUCCESS = '@issues/FETCH_ISSUE_CUSTOM_FILTERS_SUCCESS';
export const FETCH_ISSUE_CUSTOM_FILTERS_FAILURE = '@issues/FETCH_ISSUE_CUSTOM_FILTERS_FAILURE';

export const CREATE_ISSUE_REQUEST = '@issues/CREATE_ISSUE_REQUEST';
export const CREATE_ISSUE_SUCCESS = '@issues/CREATE_ISSUE_SUCCESS';
export const CREATE_ISSUE_FAILURE = '@issues/CREATE_ISSUE_FAILURE';

export const CREATE_ISSUE_V2_REQUEST = '@issues/CREATE_ISSUE_V2_REQUEST';
export const CREATE_ISSUE_V2_SUCCESS = '@issues/CREATE_ISSUE_V2_SUCCESS';
export const CREATE_ISSUE_V2_FAILURE = '@issues/CREATE_ISSUE_V2_FAILURE';

export const UPLOAD_ISSUE_PHOTO_REQUEST = '@issues/UPLOAD_ISSUE_PHOTO_REQUEST';
export const UPLOAD_ISSUE_PHOTO_SUCCESS = '@issues/UPLOAD_ISSUE_PHOTO_SUCCESS';
export const UPLOAD_ISSUE_PHOTO_FAILURE = '@issues/UPLOAD_ISSUE_PHOTO_FAILURE';

export const POST_ISSUE_COMMENT_REQUEST = '@issues/POST_ISSUE_COMMENT_REQUEST';
export const POST_ISSUE_COMMENT_SUCCESS = '@issues/POST_ISSUE_COMMENT_SUCCESS';
export const POST_ISSUE_COMMENT_FAILURE = '@issues/POST_ISSUE_COMMENT_FAILURE';

export const NOTIFY_ISSUE_MEMBER_REQUEST = '@issues/NOTIFY_ISSUE_MEMBER_REQUEST';
export const NOTIFY_ISSUE_MEMBER_SUCCESS = '@issues/NOTIFY_ISSUE_MEMBER_SUCCESS';
export const NOTIFY_ISSUE_MEMBER_FAILURE = '@issues/NOTIFY_ISSUE_MEMBER_FAILURE';

export const GET_APPROVAL_DETAILS_REQUEST = '@issues/GET_APPROVAL_DETAILS_REQUEST';
export const GET_APPROVAL_DETAILS_SUCCESS = '@issues/GET_APPROVAL_DETAILS_SUCCESS';
export const GET_APPROVAL_DETAILS_FAILURE = '@issues/GET_APPROVAL_DETAILS_FAILURE';

export const FETCH_APPROVER_LIST_REQUEST = '@issues/FETCH_APPROVERS_LIST_REQUEST';
export const FETCH_APPROVER_LIST_SUCCESS = '@issues/FETCH_APPROVERS_LIST_SUCCESS';
export const FETCH_APPROVER_LIST_FAILURE = '@issues/FETCH_APPROVERS_LIST_FAILURE';

/** Issue props */
export const SELECT_ISSUE = 'issues/SELECT_ISSUE';
export const SELECT_ISSUES = 'issues/SELECT_ISSUES';
export const READ_ISSUE = 'issues/READ_ISSUE';
export const READ_OR_UNREAD_ISSUES = 'issues/READ_OR_UNREAD_ISSUES';
export const SET_SEVERITY = 'issues/SET_SEVERITY';
export const SET_STATUS = 'issues/SET_STATUS';
export const SET_ASSIGNEE = 'issues/SET_ASSIGNEE';
export const SET_DEPARTMENT = 'issues/SET_DEPARTMENT';
export const SET_REPORTER_DEPARTMENT = 'issues/SET_REPORTER__DEPARTMENT';
export const SET_USER = 'issues/SET_USER';
export const SET_MEMBERS = 'issues/SET_MEMBERS';
export const SET_DUE_DATE = 'issues/SET_DUE_DATE';
export const SET_PRIORITY = 'issues/SET_PRIORITY';
export const SET_PRIORITY_V2 = 'issues/SET_PRIORITY_V2';
export const SET_MEMBER_DETAILS = 'issues/SET_MEMBER_DETAILS';

/** Filter query information */
export const SET_FILTERED_QUESTIONNAIRE = 'issues/SET_FILTERED_QUESTIONNAIRE';
export const SET_FILTERED_USER = 'issues/SET_FILTERED_USER';
export const SET_FILTERED_DEPARTMENT = 'issues/SET_FILTERED_DEPARTMENT';
export const SET_FILTERED_SITE = 'issues/SET_FILTERED_SITE';
export const SET_FILTERED_FLAG = 'issues/SET_FILTERED_FLAG';
export const SET_FILTERED_STATUS = 'issues/SET_FILTERED_STATUS';
export const SET_FILTERED_SECONDARY_STATUS = 'issues/SET_FILTERED_SECONDARY_STATUS';
export const SET_FILTERED_PRIORITY = 'issues/SET_FILTERED_PRIORITY';
export const SET_FILTERED_QUESTION = 'issues/SET_FILTERED_QUESTION';
export const SET_FILTERED_DATE = 'issues/SET_FILTERED_DATE';
export const SET_TEMP_FILTERED_DATE = 'issues/SET_TEMP_FILTERED_DATE';
export const SET_FILTERED_START_DATE = 'issues/SET_FILTERED_START_DATE';
export const SET_FILTERED_END_DATE = 'issues/SET_FILTERED_END_DATE';
export const SET_ISSUES_FILTERS = 'issues/SET_ISSUES_FILTERS';
export const SET_ISSUES_TEMP_FILTERS = 'issues/SET_ISSUES_TEMP_FILTERS';
export const CLEAR_ISSUES_FILTERS = 'issues/CLEAR_ISSUES_FILTERS';
export const SET_SORT = 'issues/SET_SORT';
export const SET_FILTER_QUERY = 'issues/SET_FILTER_QUERY';
export const SET_PAGE = 'issues/SET_PAGE';

/** Relational data used for filter and query */
export const SET_FILTER_DATA_QUESTIONNAIRES = 'issues/SET_FILTER_DATA_QUESTIONNAIRE';
export const SET_FILTER_DATA_ORGANIZATION = 'issues/SET_FILTER_DATA_ORGANIZATION';
export const SET_FILTER_DATA_DEPARTMENTS = 'issues/SET_FILTER_DATA_DEPARTMENTS';
export const SET_FILTER_DATA_USERS = 'issues/SET_FILTER_DATA_USERS';
export const SET_FILTER_DATA_SITES = 'issues/SET_FILTER_DATA_SITES';

/** Page configuration */
export const SET_IS_LOADING = 'issues/SET_IS_LOADING';
export const SET_IS_SINGLE_LOADING = '@issues/SET_IS_SINGLE_LOADING';
export const SET_STOP_LOADING = 'issues/SET_STOP_LOADING';
export const SCROLL_LIST = 'issues/SCROLL_LIST';
export const FETCH_ISSUES_LIST = 'issues/FETCH_ISSUES_LIST';
export const SET_PROCESSED_ISSUE = 'issues/SET_PROCESSED_ISSUE';
export const SET_IS_FILTER_OPEN = 'issues/SET_IS_FILTER_OPEN';
export const SET_SELECTED_CUSTOM_FILTER = 'issues/SET_SELECTED_CUSTOM_FILTER';
export const SET_CUSTOM_FILTERS = 'issues/SET_CUSTOM_FILTERS';
export const RESET_SELECTED_CUSTOM_FILTER = 'issues/RESET_SELECTED_CUSTOM_FILTER';
export const SET_IS_NEW_CUSTOM_FILTER_MODAL_OPEN = 'issues/SET_IS_NEW_CUSTOM_FILTER_MODAL_OPEN';

export const SET_ACTIVITY_TAB = 'issues/SET_ACTIVITY_TAB';
export const SET_ACTIVITY_ORDER = 'issues/SET_ACTIVITY_ORDER';
