import { call, put, takeLatest } from 'redux-saga/effects';
import { getToken } from 'reducers/api';
import * as actions from 'reducers/site/siteCompact/siteCompact.action';
import queryString from 'query-string';
import { apiURL } from 'config/baseURL';

import { queryStringify } from 'utils/router';

function* fetchSiteCompact({ payload }: ReturnType<typeof actions.siteCompactAction.request>) {
	const authToken = yield getToken();

	// const query: Record<string, string> = {
	// 	withDepartmentAccess: String(payload.withDepartmentAccess || false),
	// };
	const query = {
		...payload,
		withDepartmentAccess: String(payload.withDepartmentAccess || false),
	};
	const queryStr = queryStringify(query);

	const options = {
		method: 'GET',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
	};

	const siteCompactUrl = `${apiURL}/sites/compact?${queryStr}`;
	const request = () => fetch(siteCompactUrl, options);
	const response = yield call(request);
	const { data, message } = yield response.json();
	if (response && response.status === 200) {
		yield put(actions.siteCompactAction.success({ data, message }));
	} else {
		yield put(actions.siteCompactAction.failure({ message }));
	}
}

function* fetchSiteCompactPaginate({ payload }: ReturnType<typeof actions.siteCompactPaginateAction.request>) {
	// @ts-ignore
	const authToken = yield getToken();
	const options = {
		method: 'GET',
		headers: {
			Authorization: authToken,
			'Content-Type': 'application/json',
		},
	};

	const query = queryString.stringify({ ...payload });

	const siteCompactPaginateURL = `${apiURL}/sites/compact/paginate?` + query;
	const request = () => fetch(siteCompactPaginateURL, options);
	// @ts-ignore
	const response = yield call(request);
	const { data, message } = yield response.json();
	if (response && response.status === 200) {
		yield put(actions.siteCompactPaginateAction.success({ data, message }));
	} else {
		yield put(actions.siteCompactPaginateAction.failure({ message }));
	}
}

export default function* siteCompactSaga() {
	yield takeLatest(actions.siteCompactAction.request, fetchSiteCompact);
	yield takeLatest(actions.siteCompactPaginateAction.request, fetchSiteCompactPaginate);
}
