import { ProvideAuth } from 'hooks/useFirebaseAuth';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { ThemeProvider } from 'styled-components/macro';
import { GlobalStyles, main } from 'styles';
import App from './App';
import { GOOGLE_TAG_MANAGER_ID, REACT_GA_TRACKING_CODE } from './constants/Constants';
import './i18n';
import * as serviceWorker from './serviceWorker';
import { rrfProps, store } from './store/configStore';
import { sentrySetup } from './utils/sentry/sentrySetup';

import '@nimbly-technologies/audit-component/dist/index.esm.css';
import { ReactQueryClientProvider } from 'core/infrastructure/reactQuery/provider';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import 'swiper/swiper.min.css';
import './styles/tailwindoutput.css';

ReactGA.initialize(REACT_GA_TRACKING_CODE, {
	debug: false,
});

TagManager.initialize({
	gtmId: GOOGLE_TAG_MANAGER_ID,
});

sentrySetup();

ReactDOM.render(
	<ThemeProvider theme={main}>
		<>
			<GlobalStyles />
			<ReactQueryClientProvider>
				<ProvideAuth>
					<Provider store={store}>
						<ReactReduxFirebaseProvider {...rrfProps}>
							<App />
						</ReactReduxFirebaseProvider>
					</Provider>
				</ProvideAuth>
			</ReactQueryClientProvider>
		</>
	</ThemeProvider>,
	document.getElementById('root'),
);

serviceWorker.register({});

if (module.hot && process.env.NODE_ENV !== 'production') {
	module.hot.accept();
}
